import React from 'react';

import {
  Flex,
  IconButton,
  Square,
  Text,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { X } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import { Icon } from '../icons';

export function Alert({
  children,
  dismissable,
  onDismiss,
  variant = 'info',
  ...rest
}) {
  const styles = useMultiStyleConfig('Alert', { variant });

  const iconColorScheme = {
    alert: 'surface.feedback.error.sharp',
    info: 'surface.action.primary',
    success: 'surface.feedback.success.sharp',
    warning: 'surface.feedback.critical.sharp',
  };

  const textColorScheme = {
    alert: 'content.feedback.error.primary',
    info: 'content.primary',
    success: 'content.feedback.success.primary',
    warning: 'content.feedback.critical.primary',
  };

  return (
    <Flex
      __css={styles.container}
      borderRadius="md"
      variant={variant}
      {...rest}
    >
      <Flex justifyContent={dismissable ? 'space-between' : 'auto'}>
        <Flex align="flex-start">
          <Square
            bgColor={iconColorScheme[variant]}
            p="1"
            mr="3"
            borderRadius="4"
          >
            <Icon name={variant || 'info'} color="gray.0" boxSize="5" />
          </Square>
          <Text color={textColorScheme[variant]} textStyle="body1">
            {children}
          </Text>
        </Flex>
        {dismissable && (
          <IconButton
            aria-label="close"
            icon={<X />}
            onClick={onDismiss}
            size="xs"
            variant="ghost"
            color={iconColorScheme[variant]}
          />
        )}
      </Flex>
    </Flex>
  );
}

Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['alert', 'info', 'success', 'warning']),
  dismissable: PropTypes.bool,
  onDismiss: PropTypes.func,
};
