import React from 'react';

import { Box, Icon, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { RiSlackFill } from 'react-icons/ri';

export function IncidentSlackChannelLink({
  url,
  isLoading = false,
  preserveSpace = false,
  ...props
}) {
  if (isLoading === false && !url) {
    return preserveSpace ? <Box width="24px" /> : null;
  }

  return (
    <Skeleton
      display="inline-flex"
      isLoaded={url || isLoading === false}
      {...props}
    >
      <Tooltip label="Open incident slack channel" hasArrow fontSize="xs">
        <IconButton
          variant="ghost"
          size="xs"
          icon={<Icon as={RiSlackFill} boxSize="16px" />}
          as="a"
          href={url}
          target="_blank"
          rel="noopener"
          color="content.tertiary"
          aria-label="Open Slack channel"
        />
      </Tooltip>
    </Skeleton>
  );
}
IncidentSlackChannelLink.propTypes = {
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  preserveSpace: PropTypes.bool,
};
