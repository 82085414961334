import React from 'react';

import nodeEmoji from 'node-emoji';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { StyledMarkdown } from './StyledMarkdown';
import { cleanUpCodeBlocks, isUrl } from './markdown_helper';

export function Markdown({
  source,
  fontSize = 6,
  disallowedElements,
  color = 'gray-800',
}) {
  // Handle array or single string input
  const processMarkdown = (text) => {
    if (!text) return '';

    // Split into lines to handle each independently
    return text
      .split('\n')
      .map((line) => {
        // Split each line into words by whitespace
        const words = line.split(/\s+/);
        return words
          .map((word) => {
            // Preserve URLs containing emoji-like patterns (e.g., sharepoint.com/:v:/...)
            if (isUrl(word.trim())) {
              return word;
            }
            // For non-URLs: convert emoji codes to actual emojis
            return word
              .replace(/:skin-tone-\d:/gi, '') // Remove skin tone modifiers
              .replace(/:\w+:/gi, (emoji) => nodeEmoji.get(emoji)); // Convert emoji codes
          })
          .join(' '); // Reconstruct the line with preserved spacing
      })
      .join('\n'); // Reconstruct the text with preserved line breaks
  };

  // Process the source based on its type
  let filteredSource = Array.isArray(source)
    ? source.map(processMarkdown).join('\n\n') // Add double newline between array items
    : processMarkdown(source);

  // Process any code blocks in the markdown
  filteredSource = cleanUpCodeBlocks(filteredSource);

  return (
    <StyledMarkdown fontSize={fontSize} color={color}>
      <ReactMarkdown
        disallowedElements={
          disallowedElements
            ? disallowedElements
            : [
                'table',
                'caption',
                'colgroup',
                'thead',
                'tfoot',
                'tbody',
                'tr',
                'td',
                'th',
              ]
        }
        remarkPlugins={[breaks, remarkGfm]}
        skipHtml
        unwrapDisallowed
        linkTarget="_blank"
      >
        {filteredSource}
      </ReactMarkdown>
    </StyledMarkdown>
  );
}

Markdown.propTypes = {
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  fontSize: PropTypes.number,
  disallowedElements: PropTypes.array,
  color: PropTypes.string,
};
