import React from 'react';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import PropTypes from 'prop-types';

import Fonts from './font';
import defaultTheme from './index';

function EmotionCacheProvider({ nonce, children }) {
  const cache = createCache({ key: 'csp', nonce });
  return <CacheProvider value={cache}>{children}</CacheProvider>;
}

EmotionCacheProvider.propTypes = {
  nonce: PropTypes.string,
  children: PropTypes.element,
};

export const ThemeProvider = ({
  theme = defaultTheme,
  children,
  nonce,
  ...props
}) => {
  return (
    <EmotionCacheProvider nonce={nonce}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme} {...props}>
        <Fonts />
        {children}
      </ChakraProvider>
    </EmotionCacheProvider>
  );
};

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  nonce: PropTypes.string,
  children: PropTypes.element,
};
