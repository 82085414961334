import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

// Adapted from https://github.com/sindresorhus/github-markdown-css/blob/main/github-markdown.css
export const StyledMarkdown = styled(Box)`
  details,
  figcaption,
  figure {
    display: block;
  }

  summary {
    display: list-item;
  }

  [hidden] {
    display: none !important;
  }

  a {
    background-color: transparent;
    color: #0035a7;
    text-decoration: none;
    word-break: break-all;
    cursor: pointer;
    outline: transparent;
    transition: outline 0.1s ease-in 0.1s, box-shadow 0.1s ease-out 0s;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: 600;
  }

  dfn {
    font-style: italic;
  }

  mark {
    background-color: #fff8c5;
    color: #24292f;
  }

  small {
    font-size: 90%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
    max-width: 100%;
    box-sizing: content-box;
    background-color: #ffffff;
    max-width: 100%;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace;
    font-size: 1em;
  }

  figure {
    margin: 1em 40px;
  }

  hr {
    box-sizing: content-box;
    overflow: hidden;
    background: transparent;
    border-bottom: 1px solid hsla(210, 18%, 87%, 1);
    height: 0.25em;
    padding: 0;
    margin: 24px 0;
    background-color: #d0d7de;
    border: 0;
  }

  a:hover {
    text-decoration: underline;
  }

  hr::before {
    display: table;
    content: '';
  }

  hr::after {
    display: table;
    clear: both;
    content: '';
  }

  details summary {
    cursor: pointer;
  }

  details:not([open]) > *:not(summary) {
    display: none !important;
  }

  a:focus {
    outline: 2px solid #0969da;
    outline-offset: -2px;
    box-shadow: none;
  }

  a:focus:not(:focus-visible) {
    outline: solid 1px transparent;
  }

  a:focus-visible {
    outline: 2px solid #0969da;
    outline-offset: -2px;
    box-shadow: none;
  }

  a:not([class]):focus,
  a:not([class]):focus-visible {
    outline-offset: 0;
  }

  kbd {
    display: inline-block;
    padding: 3px 5px;
    font: 11px ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas,
      Liberation Mono, monospace;
    line-height: 10px;
    color: #24292f;
    vertical-align: middle;
    background-color: #f6f8fa;
    border: solid 1px rgba(175, 184, 193, 0.2);
    border-bottom-color: rgba(175, 184, 193, 0.2);
    border-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(175, 184, 193, 0.2);
  }

  p {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: var(--chakra-fontSize-${(props) => props.fontSize});
    color: var(--chakra-colors-${(props) => props.color});
  }

  p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    margin: 0;
    padding: 0 1em;
    color: #57606a;
    border-left: 0.25em solid #d0d7de;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 2em;
  }

  ol ol,
  ul ol {
    list-style-type: lower-roman;
  }

  ul ul ol,
  ul ol ol,
  ol ul ol,
  ol ol ol {
    list-style-type: lower-alpha;
  }

  dd {
    margin-left: 0;
  }

  tt,
  code,
  samp {
    font-family: FT Polar Mono, ui-monospace, SFMono-Regular, SF Mono, Menlo,
      Consolas, Liberation Mono, monospace;
    font-size: 14px;
  }

  pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: FT Polar Mono, ui-monospace, SFMono-Regular, SF Mono, Menlo,
      Consolas, Liberation Mono, monospace;
    font-size: 14px;
    word-wrap: normal;
  }

  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }

  blockquote,
  ul,
  ol,
  dl,
  table,
  pre,
  details {
    margin-top: 0;
    margin-bottom: 16px;
  }

  blockquote > :first-of-type {
    margin-top: 0;
  }

  blockquote > :last-child {
    margin-bottom: 0;
  }

  ol[type='a'] {
    list-style-type: lower-alpha;
  }

  ol[type='A'] {
    list-style-type: upper-alpha;
  }

  ol[type='i'] {
    list-style-type: lower-roman;
  }

  ol[type='I'] {
    list-style-type: upper-roman;
  }

  ol[type='1'] {
    list-style-type: decimal;
  }

  div > ol:not([type]) {
    list-style-type: decimal;
  }

  ul ul,
  ul ol,
  ol ol,
  ol ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  li > p {
    margin-top: 16px;
  }

  li + li {
    margin-top: 0.25em;
  }

  li {
    color: var(--chakra-colors-content-primary);
  }

  dl {
    padding: 0;
  }

  dl dt {
    padding: 0;
    margin-top: 16px;
    font-size: 1em;
    font-style: italic;
    font-weight: 600;
  }

  dl dd {
    padding: 0 16px;
    margin-bottom: 16px;
  }

  img[align='right'] {
    padding-left: 20px;
  }

  img[align='left'] {
    padding-right: 20px;
  }

  code,
  tt {
    padding: 0.2em 0.4em;
    margin: 0;
    white-space: break-spaces !important;
    background-color: rgba(175, 184, 193, 0.2);
    border-radius: 6px;
  }

  code br,
  tt br {
    display: none;
  }

  del code {
    text-decoration: inherit;
  }

  pre code {
    font-size: 100%;
  }

  pre > code {
    padding: 0;
    margin: 0;
    word-break: normal;
    background: transparent;
    border: 0;
    font-size: 100%;
    font-family: monospace;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 6px;
  }

  pre code,
  pre tt {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
  }

  // Adding in support for tables
  table th {
    font-weight: 600;
    color: var(--chakra-colors-content-primary);
  }

  table th,
  table td {
    padding: 6px 13px;
    border: 1px solid #d0d7de;
  }

  table td > :last-child {
    margin-bottom: 0;
  }

  table tr {
    border-top: 1px solid #d0d7de;
    color: var(--chakra-colors-content-primary);
  }

  table tr:nth-of-type(2n) {
    background-color: ;
  }

  table img {
    background-color: transparent;
  }
`;
