import React, { useRef, useState } from 'react';

import { ButtonGroup, useMergeRefs, useStyleConfig } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

export const ToggleButtonGroup = forwardRef(
  ({ children, defaultValue, isDisabled, onChange, ...props }, ref) => {
    const buttonRef = useRef(null);
    const refs = useMergeRefs(buttonRef, ref);
    const [value, setValue] = useState(defaultValue ?? 0);
    const styles = useStyleConfig('Button', props);
    // TODO: arrow keys should navigate between children
    return (
      <ButtonGroup
        border="1px solid"
        borderColor="stroke.primary"
        bg="surface.secondary"
        p="3px"
        borderRadius={styles.borderRadius}
        ref={refs}
        {...props}
      >
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child, {
            onChange: (value, isSelected, event) => {
              if (isSelected) {
                setValue(value);
                onChange?.(event, value);
              }
              child.props.onChange?.(isSelected, event);
            },
            isSelected: child.props.value === value,
            isDisabled: child.props.isDisabled || isDisabled,
          });
        })}
      </ButtonGroup>
    );
  },
);
