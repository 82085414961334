export const Table = {
  baseStyle: {
    table: {
      borderColor: 'stroke.primary',
      overflow: 'hidden', // Ensures inner elements don't overflow rounded corners
    },
    a: {
      color: 'content.primary',
    },
    thead: {
      tr: {
        _hover: {
          bg: 'transparent',
        },
        '&:first-of-type': {
          th: {
            '&:first-of-type': {
              borderTopLeftRadius: 'lg',
            },
            '&:last-of-type': {
              borderTopRightRadius: 'lg',
            },
          },
        },
      },
    },
    tbody: {
      'tr:last-of-type': {
        td: {
          '&:first-of-type': {
            borderBottomLeftRadius: 'lg',
          },
          '&:last-of-type': {
            borderBottomRightRadius: 'lg',
          },
        },
      },
    },
    th: {
      textTransform: 'initial',
      letterSpacing: 'normal',
      fontSize: 6,
      borderBottom: '1px solid',
      borderColor: 'stroke.primary',
      bg: 'surface.primary',
      color: 'content.primary',
    },
    tr: {
      position: 'relative',
      borderBottom: '1px solid',
      borderColor: 'stroke.primary',
      bg: 'surface.primary',
      '&:last-of-type': {
        borderBottom: 'none',
      },
      _hover: {
        bg: 'surface.hover.ocean',
        '&:first-of-type': {
          'th:first-of-type': {
            borderTopLeftRadius: 'lg',
          },
          'th:last-of-type': {
            borderTopRightRadius: 'lg',
          },
        },
        '&:last-of-type': {
          'td:first-of-type': {
            borderBottomLeftRadius: 'lg',
          },
          'td:last-of-type': {
            borderBottomRightRadius: 'lg',
          },
        },
      },
    },
    td: {
      px: '3',
      py: '4',
      color: 'content.primary',
    },
  },
  variants: {
    basic: {
      th: {
        fontSize: 6,
        px: '3',
        py: '3',
      },
      td: {
        fontSize: 6,
        px: '3',
        py: '4',
      },
    },
    compressed: {
      th: {
        fontSize: 6,
        px: '2',
        py: '2',
        borderBottom: '1px solid',
        bg: 'surface.primary',
      },
      td: {
        fontSize: 6,
        px: '2',
        py: '2',
        lineHeight: '1',
      },
      tr: {
        borderBottom: '1px solid',
        borderColor: 'stroke.primary',
        _hover: {
          bg: 'surface.hover.ocean',
          '&:first-of-type': {
            'th:first-of-type': {
              borderTopLeftRadius: 'lg',
            },
            'th:last-of-type': {
              borderTopRightRadius: 'lg',
            },
          },
          '&:last-of-type': {
            'td:first-of-type': {
              borderBottomLeftRadius: 'lg',
            },
            'td:last-of-type': {
              borderBottomRightRadius: 'lg',
            },
          },
        },
      },
    },
    dashboard: {
      th: {
        borderBottom: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'basic',
    size: 'md',
  },
};
