const containerStyles = {
  backgroundColor: 'surface.primary',
  borderRadius: 'lg',
  color: 'inherit',
  borderWidth: '2px',
  boxShadow: 'none',
  border: '1px solid',
  borderColor: 'stroke.primary',
};

export const Card = {
  baseStyle: {
    container: {
      ...containerStyles,
    },
    ...containerStyles,
    p: 4,
  },
  variants: {
    primary: {
      container: {
        ...containerStyles,
      },
    },
    callout: {
      container: {
        bgColor: 'surface.color.ocean.soft',
        border: '1px dashed',
        borderColor: 'stroke.ocean',
        p: 4,
      },
    },
  },
};

export const CardHeader = {
  baseStyle: {
    mb: 3,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
