import React, { useRef, useState } from 'react';

import { Button, IconButton, forwardRef, useMergeRefs } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ToggleButton = forwardRef(
  (
    {
      defaultSelected,
      isSelected: isSelectedProp, // controlled
      onChange,
      icon,
      children,
      value,
      ...props
    },
    ref,
  ) => {
    const buttonRef = useRef(null);
    const refs = useMergeRefs(buttonRef, ref);
    const isControlled = isSelectedProp !== undefined;

    const [isSelectedState, setIsSelectedState] = useState(!!defaultSelected);

    const isSelected = isControlled ? isSelectedProp : isSelectedState;

    const { onClick, ...passthroughProps } = props;

    const buttonProps = {
      'aria-pressed': isSelected ? 'true' : null,
      onClick: (event) => {
        onClick?.(event);
        if (!isControlled) {
          setIsSelectedState(!isSelected);
        }
        onChange?.(value, !isSelected, event);
      },
      ref: refs,
    };

    return icon ? (
      <IconButton
        variant="ghost"
        icon={typeof icon === 'function' ? icon(isSelected) : icon}
        value={value}
        {...buttonProps}
        {...passthroughProps}
      >
        {typeof children === 'function' ? children(isSelected) : children}
      </IconButton>
    ) : (
      <Button
        colorScheme="gray"
        variant="outline"
        borderColor={isSelected ? 'stroke.primary' : 'transparent'}
        bg={isSelected ? 'surface.primary' : 'transparent'}
        color={isSelected ? 'content.primary' : 'content.action.unselected'}
        value={value}
        {...buttonProps}
        {...passthroughProps}
      >
        {typeof children === 'function' ? children(isSelected) : children}
      </Button>
    );
  },
);
ToggleButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};
