import { cssVar } from '@chakra-ui/theme-tools';

// Define CSS variables for box shadows
const $shadowColor = cssVar('input-shadow-color');
const $focusColor = cssVar('input-focus-color');
const $invalidColor = cssVar('input-invalid-color');
const $disabledColor = cssVar('input-disabled-color');

export const TextField = {
  baseStyle: {
    fontSize: 5,
    lineHeight: 5,
    fontWeight: 5,
  },
  sizes: {
    md: {
      field: {
        border: '1px solid',
        borderColor: 'inherit',
        py: 2,
        px: 3,
      },
    },
  },
  variants: {
    search: {
      field: {
        border: '1px',
        borderColor: 'stroke.primary',
        borderRadius: '2px',
        boxShadow: 'none',
        bg: 'surface.primary',
        color: 'content.primary',
        py: '2',
        pr: '2',
        pl: '25px',
        _focus: {
          borderColor: 'stroke.action.primary',
        },
        _active: {
          borderColor: 'stroke.action.hover',
        },
        _placeholder: {
          color: 'content.tertiary',
          fontWeight: '400',
        },
      },
    },
    outline: {
      field: {
        // Set up CSS variables for the component
        [$shadowColor.variable]: 'colors.stroke.primary',
        [$focusColor.variable]: 'colors.stroke.action.primary',
        [$invalidColor.variable]: 'colors.stroke.red',
        [$disabledColor.variable]: 'colors.surface.action.disabled',

        bg: 'surface.primary',
        color: 'content.primary',
        border: 'none',
        borderRadius: 'base',
        boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,

        _readOnly: {
          userSelect: 'all',
          color: 'content.action.disabled',
          bg: 'surface.action.disabled',
          boxShadow: `0px 0px 0px 1px ${$disabledColor.reference} inset`,
          border: '1px solid',
          borderColor: 'stroke.action.disabled',

          _active: {
            boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
            _focusVisible: {
              boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
            },
          },
          _focusVisible: {
            boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
          },
        },

        _invalid: {
          boxShadow: `0px 0px 0px 1px ${$invalidColor.reference} inset`,
          _active: {
            boxShadow: `0px 0px 0px 1px ${$invalidColor.reference} inset`,
            border: 'none',
            _focusVisible: {
              boxShadow: `0px 0px 0px 1px ${$invalidColor.reference} inset`,
              border: 'none',
            },
          },
          _focusVisible: {
            boxShadow: `0px 0px 0px 1px ${$invalidColor.reference} inset`,
          },
        },

        _active: {
          boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
          _focusVisible: {
            boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
          },
        },

        _hover: {
          boxShadow: `0px 0px 0px 1px ${$shadowColor.reference} inset`,
        },

        _focusVisible: {
          boxShadow: `0px 0px 0px 1px ${$focusColor.reference} inset`,
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};
