import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { Alert, SlideFade, Text } from '@firehydrant/design-system';
import PropTypes from 'prop-types';

const Toast = ({ removeItem, toast, index, duration = 2600 }) => {
  const [animateIn, setAnimateIn] = useState(true);
  const [closeToast, setCloseToast] = useState(false);

  // Auto fade out animation
  useEffect(() => {
    if (animateIn) {
      const fadeOut = setTimeout(() => {
        setAnimateIn(false);
      }, duration);
      return () => clearTimeout(fadeOut);
    }
  }, [animateIn]);

  // Dismiss the toast
  useEffect(() => {
    if (closeToast) {
      const fadeOut = setTimeout(() => {
        removeItem(index);
      }, 200);
      return () => clearTimeout(fadeOut);
    }
  }, [closeToast, index, removeItem]);

  return (
    <SlideFade initialScale={0.2} offsetY={-20} in={animateIn}>
      <Box boxShadow="md">
        <Alert
          dismissable
          mb={2}
          onDismiss={() => {
            setAnimateIn(false);
            setCloseToast(true);
          }}
          variant={toast?.variant || 'info'}
          width={['90%', '324px', '496px']}
        >
          {toast?.variant === 'alert' && !toast?.message && (
            <Text size={6} as="span">
              Something went wrong.
            </Text>
          )}
          <Text size={6} as="span">
            {toast?.message}
          </Text>
        </Alert>
      </Box>
    </SlideFade>
  );
};

Toast.propTypes = {
  removeItem: PropTypes.func,
  toast: PropTypes.shape({
    variant: PropTypes.oneOf(['alert', 'success', 'info', 'warning']),
    message: PropTypes.string,
  }),
  index: PropTypes.number,
};

export default Toast;
