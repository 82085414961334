const codeFenceRegex = new RegExp('```.*?```', 'gs');
export const cleanUpCodeBlocks = (source) => {
  const matches = source.matchAll(codeFenceRegex);
  const newCode = [];
  let cursor = 0;
  let match_number = 1;

  for (let match of matches) {
    match_number = match_number + 1;

    if (cursor === 0 && match.index > 0) {
      newCode.push(source.substring(0, match.index));
    }

    if (cursor > 0 && cursor < match.index) {
      newCode.push(source.substring(cursor, match.index));
    }
    newCode.push('```\n', match[0].substring(3, match[0].length - 3), '\n```');
    cursor = match.index + match[0].length;
  }
  if (cursor < source.length) {
    newCode.push(source.substring(cursor));
  }

  return newCode.join('');
};

export const isUrl = (str) => {
  if (!str || typeof str !== 'string') return false;

  // First check for obviously invalid formats
  if (
    str === 'http://' ||
    str === 'https://' ||
    /^http:\/\/\.$/.test(str) ||
    /^http:\/\/\.com$/.test(str)
  ) {
    return false;
  }

  // Check if string matches pattern of just words with hyphens (not a valid URL)
  if (/^[a-zA-Z]+-[a-zA-Z-]+$/.test(str)) {
    return false;
  }

  try {
    // Add protocol if not present for URL parsing
    const urlStr = str.startsWith('http') ? str : `http://${str}`;
    const url = new URL(urlStr);

    // Check entire URL for script tags or other HTML
    if (/<[^>]*>/.test(str)) {
      return false;
    }

    const hostname = url.hostname;

    // Reject IP addresses
    if (/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/.test(hostname)) {
      return false;
    }

    // Reject if hostname contains spaces
    if (/\s/.test(hostname)) {
      return false;
    }

    // Basic hostname validation - allowing Unicode domains
    if (
      !/^[A-Za-z0-9.\u0080-\uFFFF-]+$/.test(hostname) ||
      /^\.|\.$/.test(hostname)
    ) {
      return false;
    }

    // Must have at least one dot and valid TLD
    const parts = hostname.split('.');
    if (parts.length < 2 || !/^[a-zA-Z]{2,}$/.test(parts[parts.length - 1])) {
      return false;
    }

    // Validate pathname - allow Unicode characters and common URL-safe characters
    if (url.pathname !== '/') {
      // Check for invalid characters (spaces)
      if (/\s/.test(url.pathname)) {
        return false;
      }
      // Updated pattern to allow Unicode characters and more URL-safe characters
      if (!/^\/[:\w\u0080-\uFFFF.%()/-]*$/.test(url.pathname)) {
        return false;
      }
    }

    return true;
  } catch {
    // Fallback for URLs without protocol
    // Must have valid hostname pattern with TLD
    const domainPattern =
      /^(?!-)[A-Za-z0-9\u0080-\uFFFF-]+(\.[A-Za-z0-9\u0080-\uFFFF-]+)*\.[A-Za-z]{2,}$/;
    return domainPattern.test(str);
  }
};
