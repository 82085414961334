import React from 'react';

import { Box, IconButton, Skeleton, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import MSTeamsIconGrey from '../../../../../assets/images/msteams-grey';

export function IncidentMSTeamsChannelLink({
  url,
  isLoading = false,
  preserveSpace = false,
  size = 'xs',
  ...props
}) {
  if (isLoading === false && !url) {
    return preserveSpace ? <Box width="24px" /> : null;
  }

  return (
    <Skeleton
      display="inline-flex"
      isLoaded={url || isLoading === false}
      {...props}
    >
      <Tooltip label="Open incident MSTeams channel" hasArrow fontSize="xs">
        <IconButton
          variant="ghost"
          size={size}
          icon={<MSTeamsIconGrey />}
          as="a"
          href={url}
          target="_blank"
          rel="noopener"
          color="content.tertiary"
          aria-label="Open MSTeams channel"
        />
      </Tooltip>
    </Skeleton>
  );
}
IncidentMSTeamsChannelLink.propTypes = {
  isLoading: PropTypes.bool,
  url: PropTypes.string,
  preserveSpace: PropTypes.bool,
};
