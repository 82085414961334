const standardLabel = {
  fontSize: 5,
  margin: '0 0 4px 0',
  color: 'content.primary',
  _readOnly: {
    _groupHover: { color: 'content.secondary' },
    _groupFocus: { color: 'content.secondary' },
    _groupActive: { color: 'content.secondary' },
  },
  _invalid: {
    color: 'content.primary',
    _groupHover: { color: 'content.primary' },
    _groupFocus: { color: 'content.primary' },
    _groupActive: { color: 'content.primary' },
  },
};

export const FormLabel = {
  baseStyle: { margin: '0', padding: '0' },
  sizes: {
    6: {
      fontSize: 6,
      lineHeight: 6,
      fontWeight: 6,
    },
  },
  variants: {
    primary: {
      ...standardLabel,
    },
    array: {
      ...standardLabel,
      fontSize: 6,
    },
    switch: {
      fontSize: 5,
      lineHeight: 5,
      fontWeight: 5,
      mr: 3,
      cursor: 'pointer',
      color: 'grey.900',
      _readOnly: {
        color: 'grey.700',
        cursor: 'not-allowed',
      },
    },
    checkbox: {
      color: 'grey.700',
      _invalid: {
        color: 'red.700',
      },
    },
  },
  defaultProps: {
    size: 6,
    variant: 'primary',
  },
};
