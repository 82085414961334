import { space } from '../../theme/space.theme';

export const Radio = {
  baseStyle: {},
  sizes: {
    md: {
      control: {
        w: space[4],
        h: space[4],
      },
      label: {
        fontSize: 5,
        fontWeight: 5,
        lineHeight: 5,
      },
    },
  },
  variants: {
    primary: {
      control: {
        cursor: 'pointer',
        borderRadius: 'full',
        borderColor: 'stroke.action.unselected',
        bg: 'surface.primary',
        border: '1px',
        my: '10px',
        alignSelf: 'start',
        _invalid: {
          borderColor: 'stroke.red',
          bg: 'surface.primary',
        },
        _groupHover: {
          _groupHover: {
            borderColor: 'stroke.action.primary',
            bg: 'surface.color.ocean.soft',
            _invalid: {
              bg: 'surface.feedback.error.soft',
              borderColor: 'stroke.red',
            },
          },
        },
        _groupActive: {
          _groupActive: {
            borderColor: 'stroke.ocean',
            bg: 'surface.color.ocean.regular',
            _invalid: {
              borderColor: 'stroke.red',
              bg: 'surface.feedback.error.regular',
              color: 'content.feedback.error.secondary',
            },
          },
        },
        _focus: {
          borderColor: 'stroke.action.primary',
          bg: 'surface.primary',
          _invalid: {
            borderColor: 'stroke.red',
            bg: 'surface.primary',
          },
        },
        _checked: {
          borderColor: 'stroke.action.primary',
          color: 'surface.action.primary',
          bg: 'surface.primary',
          _invalid: {
            borderColor: 'stroke.red',
            bg: 'surface.primary',
            color: 'content.feedback.error.secondary',
          },
          _hover: {
            borderColor: 'stroke.ocean',
            color: 'surface.action.primary',
            bg: 'surface.primary',
          },
          _groupHover: {
            _groupHover: {
              borderColor: 'stroke.action.primary',
              bg: 'surface.primary',
              color: 'surface.action.primary',
              _invalid: {
                borderColor: 'stroke.red',
                bg: 'surface.primary',
                color: 'content.feedback.error.secondary',
              },
            },
          },
          _groupActive: {
            _groupActive: {
              borderColor: 'stroke.ocean',
              color: 'surface.action.primary',
              bg: 'surface.primary',
              _invalid: {
                borderColor: 'stroke.red',
                bg: 'surface.feedback.error.regular',
                color: 'content.feedback.error.secondary',
              },
            },
          },
          _focus: {
            borderColor: 'stroke.action.primary',
            color: 'surface.action.primary',
            bg: 'surface.primary',
            _groupActive: {},
            _invalid: {
              borderColor: 'stroke.red',
              bg: 'surface.primary',
              color: 'content.feedback.error.secondary',
            },
          },
          _readOnly: {
            borderColor: 'stroke.action.disabled',
            bg: 'surface.tertiary',
            color: 'content.action.disabled',
            cursor: 'not-allowed',
            _focus: {
              borderColor: 'stroke.action.disabled',
              bg: 'surface.tertiary',
              color: 'content.action.disabled',
            },
            _groupHover: {
              _groupHover: {
                borderColor: 'stroke.action.disabled',
                bg: 'surface.tertiary',
                color: 'content.action.disabled',
              },
            },
            _groupActive: {
              _groupActive: {
                borderColor: 'stroke.action.disabled',
                bg: 'surface.tertiary',
                color: 'content.action.disabled',
              },
            },
          },
          _before: {
            content: `""`,
            display: 'inline-block',
            pos: 'relative',
            w: '12px',
            h: '12px',
            borderRadius: '50%',
            bg: 'currentColor',
          },
        },
        _readOnly: {
          borderColor: 'stroke.action.disabled',
          bg: 'surface.tertiary',
          color: 'surface.primary',
          cursor: 'not-allowed',
          _groupActive: {
            _groupActive: {},
          },
          _groupHover: {
            _groupHover: {
              borderColor: 'stroke.action.disabled',
              bg: 'surface.tertiary',
            },
          },
          _focus: {},
        },
      },
      label: {
        cursor: 'pointer',
        color: 'content.primary',
        py: space['mg2'],
        pl: 2,
        ml: 0,
        _invalid: {
          color: 'content.feedback.error.secondary',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
