import { cssVar } from '@chakra-ui/theme-tools';

const $bg = cssVar('tooltip-bg');
const $fg = cssVar('tooltip-fg');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowBorder = cssVar('popper-arrow-border-color');

export const Tooltip = {
  bg: 'surface.contrast',
  color: 'content.inverse',
  [$bg.variable]: 'surface.contrast',
  [$fg.variable]: 'content.inverse',
  [$arrowBg.variable]: 'surface.contrast',
  [$arrowBorder.variable]: 'stroke.action.secondary',

  px: '2',
  py: '0.5',
  fontSize: '7',
  borderRadius: 3,
  fontWeight: 'medium',
  maxW: 'xs',
  zIndex: 'tooltip',

  border: '1px solid',
  borderColor: 'stroke.action.secondary',

  '&[data-popper-arrow]': {
    bg: $bg.reference,
    borderColor: $arrowBorder.reference,
    borderWidth: '1px',
  },

  '&[data-popper-arrow]::before': {
    bg: $bg.reference,
    borderColor: $arrowBorder.reference,
  },
};
