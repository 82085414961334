import React from 'react';

import { Icon, Skeleton, Tooltip } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import { IoEye, IoEyeOutline } from 'react-icons/io5';

import { ToggleButton } from '../toggle-button';

export function WatchButton({
  isLoading,
  onChange,
  defaultSelected,
  isSelected,
  isDisabled,
  id,
  tooltipCustom,
  ...props
}) {
  return (
    <Skeleton display="inline-flex" isLoaded={!isLoading} {...props}>
      <Tooltip
        isDisabled={isDisabled}
        label={
          isSelected || defaultSelected ? 'Unwatch' : tooltipCustom || 'Watch'
        }
        hasArrow
        fontSize="7"
      >
        <ToggleButton
          icon={(isSelected) => (
            <Icon as={isSelected ? IoEye : IoEyeOutline} boxSize="16px" />
          )}
          aria-label="Watch"
          size="sm"
          color="content.tertiary"
          onChange={onChange}
          defaultSelected={defaultSelected}
          isSelected={isSelected}
          isDisabled={isDisabled}
          value={id}
        />
      </Tooltip>
    </Skeleton>
  );
}
WatchButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  defaultSelected: PropTypes.bool,
  isSelected: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
