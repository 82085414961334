import { border, extendTheme } from '@chakra-ui/react';

import { Card, CardHeader } from '../components/card/card.theme';
import { FormLabel } from '../components/form-label/formlabel.theme';
import { Link } from '../components/link/link.theme';
import { Menu } from '../components/menu/menu.theme';
import { Radio } from '../components/radio/radio.theme';
import { Tabs } from '../components/tab/tab.theme';
import { Table } from '../components/table/table.theme';
import { TextArea as Textarea } from '../components/text-area/textarea.theme';
import { TextField as Input } from '../components/text-field/textfield.theme';
import { Tooltip } from '../components/tooltip/tooltip.theme';
import * as colors from './colors.theme';
import * as fonts from './font.theme';
import * as space from './space.theme';
import * as textStyles from './typography.theme';

export const theme = extendTheme({
  ...colors,
  ...space,
  ...fonts,
  ...textStyles,
  initialColorMode: 'light',
  useSystemColorMode: false,
  shadows: {
    sm: '0px 1px 2px 0px var(--chakra-colors-shadow)',
    md: '0px 12px 24px 0px var(--chakra-colors-shadow)',
    lg: '0px 40px 64px 0px var(--chakra-colors-shadow)',
  },
  styles: {
    global: {
      'html body': {
        color: 'foreground',
        bg: 'background',
        fontWeight: 1,
      },
      p: {
        color: 'gray.800',
        fontWeight: 1,
      },
      h1: {
        fontWeight: 1,
        color: 'gray.800',
      },
      h2: {
        fontWeight: 1,
        color: 'gray.800',
      },
      h3: {
        fontWeight: 1,
        color: 'gray.800',
      },
      h4: {
        fontWeight: 1,
        color: 'gray.800',
      },
      h5: {
        fontWeight: 1,
        color: 'gray.800',
      },
      '#storybook-docs .docs-story': {
        color: 'foreground',
        bg: 'background',
      },
    },
  },
  breakpoints: {
    base: '0',
    sm: '375px',
    md: '768px',
    lg: '1024px',
    xl: '1100px',
    '2xl': '1400px',
    '3xl': '1800px',
  },
  components: {
    Alert: {
      baseStyle: {
        container: {
          borderRadius: '4px',
          fontWeight: 1,
          border: '1px solid',
          boxShadow: 'none',
          padding: 3,
        },
      },
      defaultProps: {
        variant: 'info',
      },
      variants: {
        alert: {
          container: {
            bg: 'surface.feedback.error.soft',
            color: 'content.feedback.error.primary',
            borderColor: 'stroke.red',
          },
        },
        info: {
          container: {
            bg: 'surface.primary',
            color: 'content.primary',
            borderColor: 'stroke.primary',
          },
        },
        success: {
          container: {
            bg: 'surface.feedback.success.soft',
            color: 'content.feedback.success.primary',
            borderColor: 'stroke.green',
          },
        },
        warning: {
          container: {
            bg: 'surface.feedback.critical.soft',
            color: 'content.feedback.critical.primary',
            borderColor: 'stroke.yellow',
          },
        },
      },
    },
    Avatar: {
      baseStyle: {
        container: {
          bgColor: 'primary.500',
          color: 'white',
        },
      },
      sizes: {
        '2xs': {
          container: {
            width: '18px',
            height: '18px',
            fontSize: '6px',
          },
        },
        '3xs': {
          container: {
            width: '12px',
            height: '12px',
            fontSize: '4px',
          },
        },
      },
    },
    Badge: {
      baseStyle: {
        borderRadius: 'full',
        px: 2,
        py: 1,
        textAlign: 'center',
        fontSize: 'xs',
        fontWeight: 1,
      },
      variants: {
        milestoneSelect: (props) => ({
          bg: props.colorScheme ? 'white' : `${props.colorScheme}.50`,
          color: props.colorScheme ? `${props.colorScheme}.600` : 'gray.600',
          border: '1px solid',
          borderColor: `${props.colorScheme}.200`,
          boxShadow: 'none',
          py: '6px',
          width: 'fit-content',
          _hover: {
            bg: props.colorScheme ? `${props.colorScheme}.50` : 'primary.50',
            borderColor: props.colorScheme
              ? `${props.colorScheme}.300`
              : 'gray.200',
          },
        }),
        severitySelect: (props) => ({
          bg: props.colorScheme ? `${props.colorScheme}.50` : 'white',
          color: `${props.colorScheme}.600`,
          border: '1px solid',
          borderColor: `${props.colorScheme}.200`,
          boxShadow: 'none',
          py: '6px',
          width: 'fit-content',
          _hover: {
            bg: props.colorScheme ? `${props.colorScheme}.100` : 'primary.50',
            borderColor: props.colorScheme
              ? `${props.colorScheme}.300`
              : 'gray.200',
          },
        }),
        severity: (props) => ({
          bg: props.colorScheme ? `${props.colorScheme}.50` : 'white',
          color: `${props.colorScheme}.600`,
          border: '1px solid',
          borderColor: `${props.colorScheme}.200`,
          boxShadow: 'none',
          py: '6px',
          width: 'fit-content',
        }),
        whiteSelect: {
          bg: 'white',
          border: '1px solid',
          borderColor: 'gray.200',
          boxShadow: 'none',
          py: '6px',
          width: 'fit-content',
          _hover: {
            bg: 'primary.50',
          },
        },
      },
    },
    Button: {
      sizes: {
        sm: {
          p: '8px',
          leftIcon: {
            height: '16px',
            width: '16px',
          },
          rightIcon: {
            height: '16px',
            width: '16px',
          },
        },
        md: {
          p: '12px',
          fontSize: '14px',
          leftIcon: {
            height: '16px',
            width: '16px',
          },
          rightIcon: {
            height: '16px',
            width: '16px',
          },
        },
      },
      baseStyle: {
        borderRadius: '4px',
        fontWeight: 1,
      },
      defaultProps: {
        colorScheme: 'button-primary',
        size: 'md',
        fontWeight: 1,
      },
      variants: {
        declaration: {
          backgroundColor: 'primary.600',
          color: 'white',
          border: '1px solid',
          borderColor: 'primary.800',
          px: '12px',
          _hover: {
            background: 'primary.700',
          },
        },
        outline: () => ({
          borderColor: 'stroke.action.secondary',
          border: '1px solid',
          fontSize: '6',
          backgroundColor: 'surface.primary',
          color: 'content.primary',
          _hover: {
            borderColor: 'gray.300',
          },
        }),
      },
    },
    Card,
    CardHeader,
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '4px',
          border: '1px solid',
          borderColor: 'stroke.action.unselected',
          color: 'content.primary',
          _checked: {
            bg: 'surface.primary',
            color: 'content.text.button',
            borderColor: 'stroke.action.unselected',
            _hover: {
              bg: 'surface.primary',
            },
          },
          _hover: {
            borderColor: 'stroke.action.hover',
            bg: 'surface.primary',
          },
        },
        label: {
          color: 'content.primary',
        },
      },
    },
    Container: {
      sizes: {
        1100: {
          maxW: '1100px',
        },
        1400: {
          maxW: '1400px',
        },
      },
    },
    Divider: {
      baseStyle: { borderColor: 'stroke.primary', my: 4 },
    },
    FormLabel,
    Heading: {
      baseStyle: {
        m: 0,
        fontWeight: 1,
        color: 'gray.900',
      },
      variants: {
        gradient: {
          borderImageSlice: 1,
          borderImageSource:
            'linear-gradient(270deg, #FF5300 30.79%, #220E6D 75.33%)',
          borderBottom: '2px solid',
          pb: 2,
        },
      },
    },
    IconButton: {
      sizes: {
        sm: {
          p: '8px',
        },
        md: {
          p: '12px',
        },
      },
    },
    Input,
    Link,
    List: {
      baseStyle: {
        item: {
          color: 'content.primary',
        },
      },
    },
    ListItem: {
      baseStyle: {
        color: 'content.primary',
      },
    },
    Menu,
    Popover: {
      baseStyle: {
        popper: {
          zIndex: 1500,
        },
        header: {
          bg: 'surface.primary',
          color: 'content.primary',
          fontWeight: 1,
          pl: 2,
        },
      },
    },
    MenuButton: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: 1,
      },
      defaultProps: {
        colorScheme: 'button-primary',
      },
      variants: {
        outline: {
          borderColor: 'red.600',
        },
      },
    },
    MenuDropdown: {
      baseStyle: {
        borderRadius: '4px',
        fontWeight: 1,
      },
      defaultProps: {
        colorScheme: 'button-primary',
      },
    },
    Drawer: {
      parts: ['dialog', 'header', 'body'],
      sizes: {
        xxl: {
          dialog: {
            maxW: '80vw',
          },
        },
      },
    },
    Radio,
    Switch: {
      baseStyle: {
        track: {
          borderRadius: 'full',
          border: '1px solid',
          borderColor: 'stroke.primary',
          bg: 'surface.tertiary',
          _checked: {
            bg: 'surface.action.primary',
            borderColor: 'stroke.action.secondary',
          },
          _disabled: {
            bg: 'surface.primary',
            border: 'none',
          },
        },
        thumb: {
          bg: 'white',
          boxShadow: 'none',
        },
      },
    },
    Table,
    Tabs,
    Tag: {
      baseStyle: () => ({
        container: {
          borderRadius: 'base',
          px: '8px',
          pb: '2px',
          pt: '1px',
          justifyContent: 'center',
          bg: 'surface.secondary',
          color: 'content.primary',
          lineHeight: '1',
          fontSize: '12px',
          border: '1px solid',
          borderColor: 'stroke.primary',
          boxShadow: 'none',
        },
      }),
      defaultProps: {
        size: 'unset',
      },
      variants: {
        status: (props) => ({
          container: {
            bg:
              props.colorScheme && props.colorScheme !== 'gray'
                ? `surface.color.${props.colorScheme}.soft`
                : `surface.secondary`,
            color:
              props.colorScheme && props.colorScheme !== 'gray'
                ? `content.${props.colorScheme}.primary`
                : 'content.secondary',
            border: '1px solid',
            borderColor:
              props.colorScheme && props.colorScheme !== 'gray'
                ? `stroke.${props.colorScheme}`
                : 'stroke.primary',
            boxShadow: 'none',
          },
        }),
        milestone: (props) => ({
          container: {
            borderRadius: 'full',
            bg: props.colorScheme ? 'white' : `${props.colorScheme}.50`,
            color: props.colorScheme ? `${props.colorScheme}.600` : 'gray.600',
            border: '1px solid',
            borderColor: `${props.colorScheme}.200`,
            py: '6px',
            lineHeight: '1.2',
            width: 'fit-content',
            boxShadow: 'none',
          },
        }),
        outline: () => ({
          container: {
            bg: 'gray.50',
            color: 'gray.700',
            border: '1px solid',
            borderRadius: 'full',
            lineHeight: '1.2',
            borderColor: 'gray.100',
            py: '6px',
            width: 'fit-content',
            boxShadow: 'none',
          },
        }),
        white: {
          container: {
            bg: 'white',
          },
        },
        level: (props) => ({
          container: {
            bg: 'white',
            color: `${props.colorScheme}.600`,
            border: '1px solid',
            borderColor: `${props.colorScheme}.200`,
            boxShadow: 'none',
            width: 'fit-content',
            lineHeight: '1.2',
            py: '6px',
            borderRadius: 'full',
          },
        }),
        state: (props) => ({
          container: {
            borderRadius: 'full',
            bg: 'white',
            color: props.colorScheme ? `${props.colorScheme}.600` : 'gray.600',
            border: '1px solid',
            borderColor: `${props.colorScheme}.200`,
            width: 'fit-content',
            py: '6px',
            lineHeight: '1.2',
            boxShadow: 'none',
          },
        }),
        filterpill: () => ({
          container: {
            bg: 'white',
            borderRadius: 16,
            boxShadow: 'none',
            variant: 'solid',
            fontWeight: 1,
            size: 'sm',
            px: '2',
            border: '1px',
            borderColor: 'gray.100',
            py: '0',
            display: 'flex',
            alignItems: 'center',
          },
          label: {
            bg: 'white',
            fontSize: '7',
            color: 'gray.900',
            borderRight: '1px',
            borderRightColor: 'gray.100',
            py: '1',
            px: '2',
            display: 'block',
          },
          closeButton: {
            bg: 'white',
            my: '0',
            mt: '1px',
            ml: '1',
          },
        }),
        count: {
          container: {
            border: '1px solid',
            borderColor: 'gray.300',
            boxShadow: 'none',
          },
        },
        counter: (props) => ({
          container: {
            bg:
              props?.colorScheme !== 'gray'
                ? `surface.color.${props.colorScheme}.soft`
                : 'surface.primary',
            color:
              props?.colorScheme !== 'gray'
                ? `content.${props.colorScheme}.primary`
                : 'content.secondary',
            border: 'none',
            _hover: {
              bg:
                props?.colorScheme !== 'gray'
                  ? `surface.color.${props.colorScheme}.regular`
                  : 'surface.secondary',
            },
          },
        }),
      },
    },
    Text: {
      sizes: {
        1: {
          fontSize: 1,
          lineHeight: 1,
          fontWeight: 1,
        },
        2: {
          fontSize: 2,
          lineHeight: 2,
          fontWeight: 2,
        },
        3: {
          fontSize: 3,
          lineHeight: 3,
          fontWeight: 3,
        },
        4: {
          fontSize: 4,
          lineHeight: 4,
          fontWeight: 4,
        },
        5: {
          fontSize: 5,
          lineHeight: 5,
          fontWeight: 5,
        },
        6: {
          fontSize: 6,
          lineHeight: 6,
          fontWeight: 6,
        },
        7: {
          fontSize: 7,
          lineHeight: 7,
          fontWeight: 7,
        },
      },
    },
    Textarea,
    Tooltip,
  },
});

export const pdfTheme = {
  colors: colors.colors,
  space: space.space,
  fonts: {
    body: 'FT Polar',
    heading: 'FT Polar',
    mono: 'FT Polar Mono',
  },
  fontSizes: fonts.fontSizes,
  fontWeights: fonts.fontWeights,
  lineHeights: fonts.lineHeights,

  getColor: (colorName, shade) => colors.colors[colorName][shade],
  getSpace: (size) => space.space[size],
  getFontSize: (size) => fonts.fontSizes[size],
  getLineHeight: (size) => fonts.lineHeights[size],
};

export default theme;
